<template>
	<div>
		<div v-if="table">
			<div class="box">
				<SeasonInput v-model="seasonSelected" @change="changeSeason" />

				<template v-if="seasonSelected != null && contract_ids != null">
					<CustomTable
						id_table="contract"
						:busy.sync="table_busy"
						:externSlotColumns="extern_slot_columns"
						primaryKey="contract_id"
						:hide_if_empty="true"
						:rawColumns="rawColumnNames"
						:hrefsRoutes="config_table_hrefs"
						:transformer="['ContractTransformer', 'withAllAvenantArticles']"
						:base-filters="filters"
						ref="contractTable"
						:columsAddBegin="['preview']"
            			:groupByCustom="customGroupBy"
					>
						<template v-slot:[`custom-slot-cell(avenant.avenant_free)`]="{ data }">
							<font-awesome-icon class="text-success" v-if="data.avenant && data.avenant.avenant_free" :icon="['fal', 'check-circle']" />
							<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
						</template>
						<template v-slot:[`custom-slot-cell(avenant.horse.horse_wholesire)`]="{ data }">
							<e-copybox :value="data.avenant.horse.horse_wholesire"></e-copybox>
						</template>
						<template v-slot:[`custom-slot-cell(avenant.type_monte.contract_type_monte.contracttypemonte_label)`]="{ data }">
							{{ $t(data.avenant.type_monte.contract_type_monte.contracttypemonte_label) }}
						</template>
						<template v-slot:[`custom-slot-cell(avenant.avenant_dn_bool)`]="{ data }">
							<font-awesome-icon class="text-success" v-if="data.avenant && data.avenant.avenant_dn_bool" :icon="['fal', 'check-circle']" />
							<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
						</template>
						<template v-slot:[`custom-slot-cell(avenant.avenant_as_bool)`]="{ data }">
							<font-awesome-icon class="text-success" v-if="data.avenant && data.avenant.avenant_as_bool" :icon="['fal', 'check-circle']" />
							<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
						</template>
						<template v-slot:[`custom-slot-cell(avenant.avenant_revue_bool)`]="{ data }">
							<font-awesome-icon class="text-success" v-if="data.avenant && data.avenant.avenant_revue_bool" :icon="['fal', 'check-circle']" />
							<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
						</template>
						<template v-slot:[`custom-slot-cell(contract_sent)`]="{ data }">
							<font-awesome-icon class="text-success" v-if="data.contract_sent" :icon="['fal', 'check-circle']" />
							<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
						</template>
						<template v-slot:custom-slot-cell(formatted_invoices)="{ data }">
							<p v-for="invoice in data.formatted_invoices" :key="invoice.invoice_id">
								<a href="" class="" @click.prevent="quickPreview(invoice.invoice_id)">{{invoice.invoice_num}}</a> ({{ $t('invoice.invoice_balance') }} : {{ invoice.invoice_balance }})
							</p>
						</template>
						<template v-slot:[`custom-slot-cell(paillettes_date)`]="{ data }">
							{{ data.paillettes_date }}
						</template>
						<template v-slot:[`custom-slot-cell(doses_date)`]="{ data }">
							{{ data.doses_date }}
						</template>
						<template v-slot:[`custom-slot-cell(contract_document)`]="{ data }">
							<font-awesome-icon class="text-success" v-if="data.contract_document" :icon="['fal', 'check-circle']" />
							<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
						</template>
						<template v-slot:[`custom-slot-cell(preview)`]="{ data }">
							<a href="" class="" @click.prevent="quickContractPreview(data.contract_id)">
								<font-awesome-icon :icon="['fas', 'eye']" />
							</a>
						</template>
						<template v-slot:custom-slot-cell(trackings_link)="{ data }">
							<a href="" class="" @click.prevent="openShutterTrackings(data.contract_id)">{{ $t('global.see_trackings') }}</a>
						</template>
					</CustomTable>
				</template>
			</div>
		</div>
		<div v-else>
			<main>
				<HeaderTab 
					:title="getTrad('monte.contract_list')" 
					:back_action="back_action"
				/>
				<div id="content">
					<ContractShutter :contract_id="contract_id" :version="version"></ContractShutter>
				</div>
			</main>
		</div>

		<b-modal size="xl" ref="modelPreview" hide-footer @hidden="base64 = null">
            <template v-slot:modal-title>
                {{ $t("action.previsualiser") }}
            </template>

            <iframe v-if="base64 != null" :src="'data:application/pdf;base64,' + base64" height="1000px" width="100%" style="position:relative"></iframe>
            <div v-else>
                <LoadingSpinner />
            </div>
        </b-modal>

		<b-modal ref="modalEnvoie" hide-footer>
			<template v-slot:modal-title>
				{{ $t("courrier_type.ajout") }}
			</template>

			<e-select
	            v-model="model_selected"
	            id="models"
	            track-by="model_label"
	            label="model_label"
	            class="primary"
	            :options="models"
	            :searchable="true"
	            :show-labels="false"
	            :allow-empty="false"
	        >
	            <template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
	            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
	        </e-select>

	        <div v-if="model_selected" class="text-center">
	            <b-button pill variant="primary" class="mt-3" @click.prevent="addModelCourrier"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("courrier_type.choisir") }}</b-button>
	        </div>
		</b-modal>

		<ModalContractModifRapidev2 ref="modal_contract_modif_rapide" @submit="refreshTable"/>
		<ModalSendContract ref="modal_send_contract" @submit="refreshTable"/>
		<ModalEditQualification ref="modal_edit_qualification"/>
		<ModalActDGLot ref="modal_act_dg_lot" @submit="refreshTable"/>
	</div>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import TableAction from "@/mixins/TableAction.js"
	import Navigation from "@/mixins/Navigation.js"
	import ContractMixin from "@/mixins/Contract.js"
	import Model from "@/mixins/Model.js"
	import Common from '@/assets/js/common'
	import TiersMixin from "@/mixins/Tiers.js"
	import AccountingMixin from "@/mixins/Accounting.js"
	import AccountingAccountMixin from "@/mixins/AccountingAccount.js"
	import ArticleMixin from "@/mixins/Article.js"
	import Syndic from "@/mixins/Syndic.js"
	import Invoice from "@/mixins/Invoice.js"
	import Shutter from '@/mixins/Shutter.js'
	import ShutterContract from '@/mixins/shutters-manager/Contract.js'

	export default {
		name: "ContractListv2",
		mixins: [TableAction, Navigation, ContractMixin, Model, TiersMixin, AccountingMixin, AccountingAccountMixin, ArticleMixin, Syndic, Invoice, Shutter, ShutterContract],
		props: ['horse'],
		data () {
			return {
                seasonSelected: null,
				base64: null,
                filters: {},
				table_busy: false,
				table: true,
				contract_id: null,
				contract_ids: null,
				events_tab: {
					'TableAction::goToAddContract': async () => {
						this.contract_id = await this.addContractOnline();
						this.$router.push({name: "ContractFormv2", params: {contract_id: parseInt(this.contract_id)}})
						this.table = false
					},
					'TableAction::goToEditContract': (params) => {
						this.$router.push({name: "ContractFormv2", params: {contract_id: parseInt(params.contract_id)}})
						this.contract_id = parseInt(params.contract_id);
						this.table = false

					},
					'TableAction::goToPrintContract': (contract_ids) => {
						this.callPrintContracts(contract_ids)
					},
					'TableAction::goToSendContract': (contract_ids) => {
						this.openModalSendContract(contract_ids)
					},
					'TableAction::goToCourrierContract': (contract_ids) => {
						this.callCourrierContracts(contract_ids)
					},
					'TableAction::goToDeleteContract': (contract_ids) => {
						this.deleteContract(contract_ids)
					},
					'TableAction::goToFastEditContract': (contract) => {
						this.openModalFastEditContract(contract[0])
					},
					'TableAction::goToMarkContractAsSended': (contract_ids) => {
						this.markAsSended(contract_ids)
					},
					'TableAction::goToExportContract': () => {
						this.exportContract()
					},
					'TableAction::goToEditQualificationTiers': (contracts) => {
						this.openQualificationModal(contracts)
					},
					'TableAction::goToActDgLotContract': (contracts) => {
						this.openModalActDGLot(contracts)
					},
					'TableAction::goToReindexContract': (contracts) => {
						this.reindexContract(contracts)
					},
				},
				rawColumnNames: [
					"articles",
					"seasonmarestallion.seasonmarestallion_status",
					"contract_note",
					"avenant.avenant_justification",
					"avenant.avenant_comment"
				],
				config_table_hrefs: {
					'contract_num': {
						routeUniqueName: 'ContractFormv2',
						routeName: 'ContractFormv2',
						params: {
							contract_id: 'contract_id'
						}
					},
					'contract_tiers[0].tiers.tiers_rs': {
						routeUniqueName: 'tiersTableauBord',
						routeName: 'tiersTableauBord',
						params: {
							tiers_id: 'contract_tiers.0.tiers.tiers_id'
						}
					},
					'avenant.horse.horse_nom': {
						routeUniqueName: 'MareFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'avenant.avenant_horse'
						}
					},
					'config.horse.horse_nom': {
						routeUniqueName: 'StallionFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'config.horse.horse_id'
						}
					},
					'avenant.cmep.tiers_rs': {
						routeUniqueName: 'cmepFiche',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'avenant.cmep.tiers_id'
                        }
                    },
					'courtier.tiers_rs': {
						routeUniqueName: 'courtierFiche',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'courtier.tiers_id'
                        }
                    },
					'avenant.porteuse.horse_nom': {
						routeUniqueName: 'MarePorteuseFiche',
						routeName: 'horseFiche',
						params: {
							horse_id: 'avenant.porteuse.horse_id'
						}
					}
				},
				extern_slot_columns: [
					'avenant.avenant_free',
					'avenant.avenant_dn_bool',
					'avenant.avenant_as_bool',
					'avenant.avenant_revue_bool',
					'avenant.type_monte.contract_type_monte.contracttypemonte_label',
					'formatted_invoices',
					'paillettes_date',
					'doses_date',
					'contract_document',
					'preview',
					'trackings_link',
					'avenant.horse.horse_wholesire'
				],
				model_selected: {},
				models: [],
				contracts_selected: [],
				processing: false,
				version: 2,
				customGroupBy: {'avenant.type_monte.contract_type_monte.contracttypemonte_label': this.getTrad},
			}
		},
		created() {
			this.init_component()
			EventBus.$on("Swipper::TabClicked", (params) => {
				if(params.href == "ContractMareList" && !this.table_busy)
				{
					this.table = true
					// this.$refs.contractTable.refreshTable()
				}
			})

			EventBus.$on("ContractMareList::reloadTabs", (params) => {
				if(params.href == "ContractMareList" && !this.table_busy)
				{
					this.table = true
					// this.$refs.contractTable.refreshTable()
				}
			})
		},
		methods: {
			async init_component() {
				this.table_busy = true

				if(this.$route.params.contract_id)
				{
					this.table = false
					this.contract_id = parseInt(this.$route.params.contract_id)
				}
				else
				{
					this.table = true
					this.contract_id = null
				}

			},
			async deleteContract(contract_ids) {
				try {
					await this.deleteContracts(contract_ids)
				}
				catch(error) {
					EventBus.$emit('App::failureToast', 'error.DC')
				}
				
				this.$refs.contractTable.refreshTable()
			},
			async callSendContracts(contract_ids) {
				let result = await this.sendMailContracts(contract_ids)
				if(result != null){
					this.successToast('monte.send_success')
					await this.markSendedContract(contract_ids)
					.then(() => {
						this.$refs.contractTable.refreshTable()
					})
				}
				else
				{
					this.failureToast('monte.send_error')
				}
				EventBus.$emit("TableAction::stopSpin")
			},
			async callPrintContracts(contract_ids) {
				await Common.asyncForEach(contract_ids, async (contract_id) => {
					let result = await this.printContract(parseInt(contract_id))
					if(result == null){
						this.failureToast('monte.print_error')					
					}
					EventBus.$emit("TableAction::stopSpin")
				})
			},
			async callCourrierContracts(contract_ids) {
                const list_type = ['contract_envoie', 'contract_envoie_adresse']
                this.models = await this.loadAllModel(0, list_type)
                this.contracts_selected = contract_ids
                this.$refs.modalEnvoie.show()
				EventBus.$emit("TableAction::stopSpin")
			},
			async addModelCourrier() {
				this.processing = true
				for(let i in this.contracts_selected) {
					const model = await this.createModel(this.contracts_selected[i], this.model_selected.type.modeltype_id, this.model_selected.model_label, 'contract_envoie')
					await this.cloneModel(this.model_selected.model_id, model.model_id)
					await this.generateCourrier(model.model_id, this.model_selected.type.modeltype_template, this.contracts_selected[i])
					await this.printCourrier(model.model_id, this.model_selected.model_label + "_" + this.contracts_selected[i])
				}
				this.processing = false
                this.$refs.modalEnvoie.hide()
			},
			back_action(){
				this.goBack()
				this.table = true
				if (this.$refs.contractTable) {
					this.$refs.contractTable.refreshTable()
				}
			},
			async changeSeason() {
				if(!this.seasonSelected) return

				// Récupérer les contract_config de la saison
				const contract_config_ids = await this.$storage.db.t('contract_config')
				.then(table => {
					return table.where({contractconfig_season: this.seasonSelected.id}).primaryKeys()
				})

				// Récupérer les contrats avec les contract_config de la saison
				this.contract_ids = await this.$storage.db.t('contract')
				.then(table => {
					return table.where('contract_contractconfig').anyOf(contract_config_ids).primaryKeys()
				})

				this.filters = {
					is_validated: {
						column: 'contract_id',
						operator: 'isInList',
						value: this.contract_ids
					}
				}
			},
			openModalFastEditContract(contract) {
				let contract_avenant = contract.avenant
				let infos_contract = {
					contract_id: contract.contract_id,
					contract_note: contract.contract_note,
					contractconfig_horse: contract.config.contractconfig_horse,
					contractconfig_season: contract.config.contractconfig_season
				}
				let infos_avenant = {
					avenant_id: contract_avenant.avenant_id, 
					avenant_dn: contract_avenant.avenant_dn,
					avenant_dn_bool: contract_avenant.avenant_dn_bool,
					avenant_dps: contract_avenant.avenant_dps, 
					avenant_as_bool: contract_avenant.avenant_as_bool, 
					avenant_date_signature: contract_avenant.avenant_date_signature, 
					avenant_date_validation: contract_avenant.avenant_date_validation, 
					avenant_status: contract_avenant.avenant_status,
					avenant_type_contrat: contract_avenant.avenant_type_contrat,
					avenant_comment: contract_avenant.avenant_comment
				}
				this.$refs.modal_contract_modif_rapide.openModal(infos_contract, infos_avenant)
			},
			async markAsSended(contract_ids) {
				await this.markSendedContract(contract_ids)
				.then(() => {
					this.$refs.contractTable.refreshTable()
				})
			},
			openModalSendContract(contract_ids) {
				this.$refs.modal_send_contract.openModal(contract_ids)
			},
			refreshTable() {
				EventBus.$emit("TableAction::stopSpin")
				this.$refs.contractTable.refreshTable()
			},
			openQualificationModal(contract_ids) {
				this.$refs.modal_edit_qualification.openModal(contract_ids)
			},
			async quickContractPreview(contract_id) {
                this.$refs.modelPreview.show()
                let retour = await this.printContract(contract_id, true)
                if(retour) {
                    this.base64 = retour
                }
            },
			async quickPreview(invoice_id) {
                this.$refs.modelPreview.show()
                let retour = await this.pdfInvoice([invoice_id], "", true)
                if(retour) {
                    this.base64 = retour
                }
            },
			async exportContract() {
				await this.loadExportContract(this.seasonSelected.id)
			},
			async delExportContract() {
				await this.deleteExportContract()
			},
			openModalActDGLot(contracts) {
				this.$refs.modal_act_dg_lot.openModalContract(contracts)
			},
			async reindexContract(contracts) {
				const contract_id = contracts.map(contract => contract.contract_id)
				await this.forceIndexationContract(contract_id)
				this.$refs.contractTable.refreshTable()
			},
			openShutterTrackings(contract_id) {
                this.openTrackingsShutter(contract_id)
            }
		},
		watch: {
			$route(){
				if(this.$route.params.contract_id)
				{
					this.table = false
					this.contract_id = parseInt(this.$route.params.contract_id)
				}
				else
				{
					this.table = true
					this.contract_id = null
				}
			}
		},
		components: {
			HeaderTab: () => import('@/components/HeaderTab'),
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
			ContractForm : () => import('@/components/Contract/ContractForm'),
			ContractShutter : () => import('@/components/Contract/ContractShutter'),
			SeasonInput : () => import('@/components/Inputs/SeasonInput'),
			ModalContractModifRapidev2: () => import('@/components/Contract/ModalContractModifRapidev2'),
			ModalSendContract: () => import('@/components/Contract/ModalSendContract'),
			ModalEditQualification: () => import('@/components/Contract/ModalEditQualification'),
			ModalActDGLot : () => import('@/components/Gynecologie/ModalActDGLot')
		},
	}
</script>
